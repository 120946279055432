.modal-loader-icon {
    animation: zoom-in-zoom-out 1.5s ease-out infinite;
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.15, 1.15);
    }
    100% {
        transform: scale(1, 1);
    }
}